import Keycloak from 'keycloak-js'

export async function connectKeycloak({ accessToken, refreshToken, url, realm, clientId, refreshInSeconds = 30 } = {}) {
    const keycloakConfig = {
        url,
        realm,
        clientId
    }

    const keycloak = new Keycloak(keycloakConfig)

    const initOptions = {
        useNonce: true,
        checkLoginIframe: false,
        token: accessToken,
        refreshToken
    }
    
    if (!accessToken && !refreshToken) {
        initOptions.onLoad = 'login-required'
    }

    return keycloak.init(initOptions)
    .catch(err => {
        if (initOptions.onLoad == 'login-required') {
            throw err
        } 
        //if error with given accessToken, refreshToken 
        //--> restart without tokens and show login-screen
        initOptions.token = undefined
        initOptions.refreshToken = undefined
        initOptions.onLoad = 'login-required'
        return new Keycloak(keycloakConfig).init(initOptions)
    })    
    .then(authenticated => {
        setInterval(() => {
            if (keycloak.authenticated) {
                keycloak.updateToken(900)
                .catch(() => {
                    keycloak.logout()
                })
            }
        }, refreshInSeconds * 1000)

        if (authenticated) {
            return keycloak
        } else {
            throw 'kc-connection-failed'
        }
    })
}