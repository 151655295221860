<template>
    <div class="ellipsis">
        <div :style="color ? `background: ${color};` : ''"></div>
        <div :style="color ? `background: ${color};` : ''"></div>
        <div :style="color ? `background: ${color};` : ''"></div>
    </div>
</template>

<script>
export default {
    name: 'LoadingEllipsis',
    props: {
        color: String
    }
}
</script>

<style lang="scss" scoped>
.ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 13px;

    div {
        position: absolute;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: var(--sys-primary);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    div:nth-child(1) {
        left: 8px;
        animation: pulsatefirst 1s infinite;
    }

    div:nth-child(2) {
        left: 32px;
        animation: pulsatesecond 1s infinite;
    }

    div:nth-child(3) {
        left: 56px;
        animation: pulsatethird 1s infinite;
    }

}





@keyframes pulsatefirst {
    0% {
        transform: scale(1)
    }

    25% {
        transform: scale(0)
    }

    50% {
        transform: scale(1)
    }

    75% {
        transform: scale(1)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes pulsatesecond {
    0% {
        transform: scale(1)
    }

    25% {
        transform: scale(1)
    }

    50% {
        transform: scale(0)
    }

    75% {
        transform: scale(1)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes pulsatethird {
    0% {
        transform: scale(1)
    }

    25% {
        transform: scale(1)
    }

    50% {
        transform: scale(1)
    }

    75% {
        transform: scale(0)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes grow {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes shrink {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes moveright {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
</style>