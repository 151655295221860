<template>
    <div id="app">
        <MenuBox />
        <router-view />
    </div>
</template>

<script>
import MenuBox from '@components/MenuBox.vue'
import './assets/css/application.scss';
import './assets/css/toasts.css';


export default {
    name: 'App',
    inject: ['globalState'],
    components: {
        MenuBox
    },
    mounted() {
        this.initCustomTheme()
    },
    methods: {
        initCustomTheme() {
            const theme = this.globalState.config.theme?.trim().toLowerCase()
            const iconUrl = `/${theme}.ico`
            let themeUrl = `/themes/${theme}.css`

            if (!theme) {
                themeUrl = `/themes/default.css`
            }

            fetch(themeUrl)
            .then(response => {
                if (response.status === 200) {
                    const themeLinkEl = document.createElement('link')
                    themeLinkEl.rel = 'stylesheet'
                    themeLinkEl.href = themeUrl
                    return document.querySelector("head").appendChild(themeLinkEl)
                }
                throw response.status
            })
            .catch(() => console.warn('Theme not found', themeUrl))

            fetch(iconUrl)
            .then(response => {
                if (response.status === 200) {
                    return document.querySelector("link[rel~='icon']").href = iconUrl
                }
                throw response.status
            })
            .catch(() => console.warn('Icon not found', iconUrl))
        }
    }
}

</script>

<style lang="scss">

</style>
