<template>
    <VueDatePicker  v-if="isMobileWidth" class="date-input" v-model="inputValue" placeholder="TT.MM.JJJJ" :clearable="false" teleport-center :enable-time-picker="false" locale="de" cancelText="Abbrechen" selectText="Auswählen" format="dd.MM.yyyy">
        <template #input-icon>
            <IconsCalendar class="calendar-icon"/>
        </template>
        <template #action-preview>
        </template>
    </VueDatePicker>
    <VueDatePicker v-else class="date-input" v-model="inputValue" placeholder="TT.MM.JJJJ" :clearable="false" text-input :enable-time-picker="false" locale="de" cancelText="Abbrechen" selectText="Auswählen" format="dd.MM.yyyy">
        <template #input-icon>
            <IconsCalendar class="calendar-icon"/>
        </template>
        <template #action-preview>
        </template>
    </VueDatePicker>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const EMIT_INPUT = 'update:modelValue'

export default {
    name: 'date-picker',
    emits: [ EMIT_INPUT ],
    props: {
        modelValue: {
            type: Date
        }
    },
    computed: {
        inputValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit(EMIT_INPUT, value)
            }
        },
        isMobileWidth() {
            if (window.matchMedia("(max-width: 800px)").matches){
                return true
            }
            return false
        }
    },
    components: {
        VueDatePicker
    }
}
</script>

<style lang="scss" scoped>

.date-input {
    --dp-input-padding: 7px 0px 7px 12px;
    --dp-input-icon-padding: 40px;
    --dp-background-color: var(--sys-surface-bright);
    --dp-text-color: var(--sys-on-surface);
    --dp-border: solid 1px var(--sys-outline);
    --dp-border-color: var(--sys-outline);

    :deep(.dp__theme_light) {
        --dp-background-color: var(--sys-surface-bright);
        --dp-primary-color: var(--sys-primary);
        --dp-text-color: var(--sys-on-surface);
        --dp-primary-text-color: var(--sys-on-primary);
        --dp-font-size: 16px;
        --dp-border-radius: 4px;
        --dp-input-padding: 7px 12px 7px 12px;
        --dp-input-icon-padding: 44px;
        --dp-action-button-height: 40px;
        --dp-action-buttons-padding: 12px 12px;
        --dp-preview-font-size: 14px;
        --dp-font-family: var(--sys-main-font);
    }
    :deep(.dp__action_buttons) {
        gap: 4px;
    }

    .calendar-icon {
        width: 18px;
        height: 18px;
        // // // margin-top: 4px;
        margin: 4px 0px 0px 12px;
        color : var(--sys-on-surface);
    }
}

</style>