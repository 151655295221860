<template>
    <div class="searchbox-container">
        <!-- <div class="searchbox-header">
            <LanguageChanger class="changer"/>
        </div> -->
        <div  v-for="point in inputValue" :key="point.id" class="point-container">
            <label>{{point.placeholder}}</label>
            <div class="location-search">
                <LocationAutoCompleteSearch class="location-input" :inputid="'input-point-'+point.id"  :key="point.id"  :location="point" @select-location="location => selectLocation(location, point)" @stoptime-input="stoptimeInput($event, point)" @clear-locationname="clearLocationName(point)" @location-input="onLocationInput" />                    
                <button class="secondary round remove" v-show="point.waypoint" name="removebutton" value="x" @click="removeLocation(point)">
                    <IconsDelete/>
                </button>
            </div>
        </div>
        <div class="add-waypoint">
            <button class="primary round" name="addingWaypoint" value="+" @click="addLocation">
                <IconsAdd/>
            </button>
            <label class="plusbuttonlabel" for="addingWaypoint" @click="addLocation" >Wegpunkt hinzufügen</label>
        </div>
    </div>
</template>

<script>
import LocationAutoCompleteSearch from '@systems/LocationAutoCompleteSearch.vue'
import { LngLat } from 'maplibre-gl'

const EMIT_INPUT = 'update:modelValue'
const EMIT_RIDEPOINT_CHANGE = 'update-ridepoints'
const EMIT_LOCATION_INPUT = 'location-input'
const EMIT_TIME_INPUT = 'stoptime-input'


export default {
    name: 'ride-point-search',
    emits: [ EMIT_INPUT, EMIT_RIDEPOINT_CHANGE, EMIT_LOCATION_INPUT, EMIT_TIME_INPUT],
    props: {
        modelValue: {
            type: Array,
            required: true
        }
    },
    methods: {
        addLocation: function() {
            const aktCnt = this.inputValue.length;
            let newPoint = {
                    id: (Math.floor(Math.random() * Date.now())),
                    name: '',
                    placeholder: 'Wegpunkt',
                    waypoint: true,
                    latitude: 0,
                    longitude: 0,
                    stoptime: null,
                    marker: null
            }
             //Wegpunkt an vorletzter Stelle einfügen
            this.inputValue.splice(aktCnt-1, 0, newPoint)
            this.$emit(EMIT_RIDEPOINT_CHANGE)
        },
        removeLocation: function(point) {
            //Element aus ridepoints entfernen
            const index = this.inputValue.indexOf(point)
            this.inputValue.splice(index, 1);
            this.$emit(EMIT_RIDEPOINT_CHANGE)
        },

        selectLocation: function(event, point) {
            if (event) {
                //Daten in ridePoint-Array übertragen
                point.name = event.name
                point.longitude = event.position.longitude
                point.latitude = event.position.latitude
                //Marker für Karte definieren
                let position = new LngLat(event.position.longitude, event.position.latitude)
                let marker = {
                    display: event.name,
                    position: position
                }
                point.marker = marker
            } else {
                point.marker = null
            }
            this.$emit(EMIT_RIDEPOINT_CHANGE)
        },
        clearLocationName: function(point) {
           point.name = '';
           point.latitude = 0;
           point.longitude = 0;
           point.marker = null;
           this.$emit(EMIT_RIDEPOINT_CHANGE)
        },
        stoptimeInput: function(time, point) {
            point.stoptime = time;
            this.$emit(EMIT_TIME_INPUT);
        },
        onLocationInput:function () {
            this.$emit(EMIT_LOCATION_INPUT);
        }
    },
    computed: {
        inputValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit(EMIT_INPUT, value)
            }
        }
    },
    components: {
        LocationAutoCompleteSearch,
        // LanguageChanger,
    }
}
</script>

<style lang="scss"  scoped>

.searchbox-container {
    width: 100%;
    // height: fit-content;
    margin-bottom: 20px;

    .point-container{
        width: 100%;
        margin-top: 0px;
        label {
            margin-bottom: 4px;
        }
        .location-search{
            display: flex;
            gap: 8px;

            .remove {
                padding: 10px;
            }
        }
    }


    .add-waypoint{
        display: flex;
        align-items: center;
    }


}


/* Nur für Bildschirmgrößen bis maximal 400px */
@media (max-width: 800px) {
    .searchbox-container .point-container {
        .location-search {
            display: block;
        }

        .remove {
            margin-top: -49px;  
            margin-right: 12px; 
            float: right;
        }
    }
}

// @media (max-width: 800px) {

//     .point-container{

//         position: relative;
//         .remove {
//             position: absolute;
//             right: 10px;
//             top: 50px;
//             z-index: 2;
//         }
//     }

// }

</style>