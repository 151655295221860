<template>
    <div>
        <label for="validity-options" >Wie oft fährst du?</label>
        <div class="validity-container">
            <select name="validity-options" id="optionlist" class="validity-options" @change="oneTimeUpdate($event)" v-model="selectedOption">
                <option value="ontime">Einmalig</option>
                <option value="regularly">Regelmäßig</option>
            </select>
            <DatePicker v-if="inputValue.onetimedrive" class="date-picker" v-model="inputValue.onetimedate"></DatePicker>
        </div>
    </div>
    <div v-if="!inputValue.onetimedrive" class="regularly-container">
        <div class="sub-radio">
            <div class="day-block mo-fr">
                <CheckBoxInput class="checkbox" label="Mo" :value="montag" v-model="inputValue.ridedays.monday"></CheckBoxInput>
                <CheckBoxInput class="checkbox" label="Di" :value="tuesday" v-model="inputValue.ridedays.tuesday"></CheckBoxInput>
                <CheckBoxInput class="checkbox" label="Mi" :value="wednesday" v-model="inputValue.ridedays.wednesday"></CheckBoxInput>
                <CheckBoxInput class="checkbox" label="Do" :value="thursday" v-model="inputValue.ridedays.thursday"></CheckBoxInput>
                <CheckBoxInput class="checkbox" label="Fr" :value="friday" v-model="inputValue.ridedays.friday"></CheckBoxInput>
            </div>
            <div class="day-block sa-so">
                <CheckBoxInput class="checkbox" label="Sa" :value="saturday" v-model="inputValue.ridedays.saturday"></CheckBoxInput>
                <CheckBoxInput class="checkbox" label="So" :value="sunday" v-model="inputValue.ridedays.sunday"></CheckBoxInput>
            </div>
        </div>
        <div class="date-intervals">
            <DateIntervalBox v-model="inputValue.dateintervals" @date-from-input="handleIntervalFromInput" @date-until-input="handleIntervalUntilInput" @add-interval="addInterval"  @remove-interval="removeInterval"/>
        </div>
    </div>
</template>

<script>
import DateIntervalBox from '@components/systems/DateIntervalBox.vue'
import CheckBoxInput from './systems/elements/CheckBoxInput.vue';
import DatePicker from '@elements/DatePicker.vue'

const EMIT_INPUT = 'update:modelValue'

export default {
    name: 'validity-input',
    emits: [ EMIT_INPUT ],
    props: {
        modelValue: {
            onetimedrive: true,
            onetimedate: new Date(),
            dateintervals: {type: Array},
            ridedays: {
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: false,
                sunday: false
            }
        }
    },
    methods: {
        handleIntervalFromInput: function(value, index) {
            this.inputValue.dateintervals[index].dateFrom = value;
        },
        handleIntervalUntilInput: function(value, index) {
            this.inputValue.dateintervals[index].dateUntil = value;
        },
        addInterval: function() {
            //neues Zeitbereichintervall erzeugen
            let newInterval = {
                    id: (Math.floor(Math.random() * Date.now())),
                    dateFrom: new Date(),
                    dateUntil: new Date()
            }
            this.inputValue.dateintervals.push(newInterval);
        },
        removeInterval: function(index) {
           //Zeitbereichintervall entfernen
           if (index > -1) {
              this.inputValue.dateintervals.splice(index, 1);
           }
        },
        oneTimeUpdate: function(val) {
            if(val.target.value === 'ontime') {
               this.inputValue.onetimedrive = true;
            } else {
               this.inputValue.onetimedrive = false;
            }
        }
    },
    computed: {
        inputValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit(EMIT_INPUT, value)
            }
        },
        selectedOption() {
            if(this.inputValue.onetimedrive) {
                return 'ontime'
            } else {
                return 'regularly'
            }
        }
    },
    components: {
        DateIntervalBox,
        CheckBoxInput,
        DatePicker
    }
}
</script>


<style lang="scss"  scoped>
.validity-container {

    display: flex;
    margin-top: 4px;
    margin-right: 48px;

    >.validity-options {
        width: calc(100% - 224px);
        max-width: 600px;
        height: 40px;
        padding: 8px 12px 8px 12px;
        border-radius: 4px;
        border: solid 1px var(--sys-outline);
        background-color: var(--sys-surface-bright);
        font-size: 16px;
        color: var(--sys-on-surface-variant);
    }


    .date-picker {
        width: 216px;
        min-width: 150px;
        margin-left: 8px;
    }
}

.regularly-container{


    .sub-radio{
        display: flex;
        color: var(--sys-on-surface);

        .day-block {
            display: flex;
            align-items: center;
            margin: 8px 0px 4px 0px;
            .checkbox{
                margin: 12px 0px 12px 8px;
            }
        }
    }
}
.date-intervals{
    margin-bottom: 10px;
}

@media (max-width: 500px) {
    .validity-container {
        margin-right: 0px;
        >.validity-options {
            width: calc(100% - 158px);
        }
        .date-picker {
            width: 150px;
        }
    }

    .regularly-container{
        .sub-radio{
            display: block;
            .day-block {
                margin-bottom: 0px;
            }
            .sa-so {
                margin-top: 0px;
            }
        }

    }
}


</style>