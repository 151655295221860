import IconsAddVue from "./IconsAdd.vue"
import IconsCarVue from "./IconsCar.vue"
import IconsMenuVue from "./IconsMenu.vue"
import IconsEditVue from "./IconsEdit.vue"
import IconsErrorVue from "./IconsError.vue"
import IconsClockVue from "./IconsClock.vue"
import IconsCheckVue from "./IconsCheck.vue"
import IconsLogoutVue from "./IconsLogout.vue"
import IconsDeleteVue from "./IconsDelete.vue"
import IconsCalendarVue from "./IconsCalendar.vue"
import IconsRegisterVue from "./IconsRegister.vue"
import IconsHomeOutlineVue from "./IconsHomeOutline.vue"
import IconsLocationPinVue from "./IconsLocationPin.vue"
import IconsCheckBoxCheckedVue from "./IconsCheckBoxChecked.vue"
import IconsCheckBoxOutlineVue from "./IconsCheckBoxOutline.vue"
import IconsEllipsisVue from "./IconsEllipsis.vue"

const appIcons = {
    install(app) {
        app.component('IconsAdd', IconsAddVue)
        app.component('IconsCar', IconsCarVue)
        app.component('IconsClock', IconsClockVue)
        app.component('IconsEdit', IconsEditVue)
        app.component('IconsError', IconsErrorVue)
        app.component('IconsMenu', IconsMenuVue)
        app.component('IconsCheck', IconsCheckVue)
        app.component('IconsLogout', IconsLogoutVue)
        app.component('IconsDelete', IconsDeleteVue)
        app.component('IconsCalendar', IconsCalendarVue)
        app.component('IconsRegister', IconsRegisterVue)
        app.component('IconsHomeOutline', IconsHomeOutlineVue)
        app.component('IconsLocationPin', IconsLocationPinVue)
        app.component('IconsCheckBoxChecked', IconsCheckBoxCheckedVue)
        app.component('IconsCheckBoxOutline', IconsCheckBoxOutlineVue)
        app.component('IconsEllipsis', IconsEllipsisVue)
    }
}

export default appIcons