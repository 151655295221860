<template>
    <div class="navbar">
        <nav class="navigation-icon">
            <button class="menu-button" name="showHideMenu" value="+" @click="showOrHideMenu">
                <IconsMenu class="menu-icon"/>
            </button>
            <p class="title-selected">{{ titleSelected }}</p>
        </nav>
        <nav class="navigation-direct">
            <router-link to="/">Home</router-link>
            <router-link to="/rideoffer">Neue Fahrt</router-link>
            <router-link to="/my-rideoffers">Meine Fahrten</router-link>
        </nav>
        <AccountSandbar class="account-sandbar"/>
        <div class="menu-container" v-show="menuopen" @click="$event.stopPropagation()" >
            <div class="links">
                <router-link to="/" @click="clickLink">Home
                    <IconsHomeOutline class="link-icon"/>
                </router-link>
                <router-link to="/rideoffer" @click="clickLink">Neue Fahrt
                    <IconsAdd class="link-icon"/>
                </router-link>
                <router-link class="last-menu" to="/my-rideoffers" @click="clickLink">Meine Fahrten
                    <IconsCar class="link-icon"/>
                </router-link>
            </div>
            <div class="account-details">
                <AccountDetails/>
            </div>
        </div>
    </div>
</template>
<script>
import AccountSandbar from '@systems/AccountSandbar.vue'
import AccountDetails from '@systems/AccountDetails.vue'

export default {
    name: 'menu-box',
    data() {
        return {
            menuopen: false
        }
    },
    methods: {
        clickLink() {
            this.hideMenu()
        },
        showOrHideMenu(event) {
            this.menuopen = !this.menuopen
            if (this.menuopen) {
                if (event) {
                    event.stopPropagation()
                }
                // register click-outside event on body
                document.addEventListener('click', this.hideMenu)
            } else {
                document.removeEventListener('click', this.hideMenu)
            }
        },
        hideMenu() {
            this.menuopen = false
        }
    },
    components: {
    AccountSandbar,
    AccountDetails
},
    computed: {
        titleSelected() {
            return this.$route.meta.displayTitle
        }
    }
}
</script>

<style lang="scss" scoped>

.navbar{
    position: fixed;
    width:100%;
    z-index: 10;
}
.menu-container {
    position: absolute;
    z-index: 20;
    top: 71px;
    left: 16px;
    width: 235px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 var(--sys-shadow);
    background-color: var(--sys-surface);

    .links{
        padding: 8px 8px 4px 8px;
        a {
            font-weight: 600;
            font-size: 14px;
            height: 48px;
            color: var(--sys-on-surface);
            display: block;
            margin: 0px 7px;
            padding: 15px 0px;
            border-bottom: solid 1px var(--sys-outline-variant);
            text-decoration: none;

            &.router-link-exact-active {
                color: var(--sys-inverse-surface);
            }
            .link-icon{
                float: right;
            }
        }
        .last-menu{
            border-bottom: 0px;
        }
    }

    .account-details{
        padding-bottom: 8px;
        border-top: solid 1px var(--sys-outline);
    }
}

nav {
    padding: 29px 0px 28px 40px;
    // background-color: var(--maincolor, #064A6C);
    background-image: var(--background-gradient, linear-gradient(121deg, #00296e -5%, #002a6f -5%, #0c68b0 52%));
    height: 85px;
    a {
        font-size: 22px;
        height: 25px;
        color: var(--sys-on-primary);
        text-decoration: none;
        margin-right: 40px;

        &.router-link-exact-active {
            text-decoration: underline;
        }
    }
}


.navigation-icon {
    display: none;
    padding-top: 0px;
    height: 75px;
    margin: 0px;

    color: var(--sys-on-primary);
    .title-selected{
        font-size: 20px;
        padding-top: 24px;
        margin: 0 auto;
    }
    .menu-button {
        position: absolute;
        height: 50px;
        width: 50px;
        margin-top: 12px;
        padding: 13px 12px;
        border: 0px;
        background-color: transparent;

        .menu-icon {
            height: 24px;
            width: 24px;
            color: var(--sys-on-primary);
        }
    }
}





.navigation-direct {
    padding-left: 40px;
}

@media (max-width: 600px) {

    .navigation-direct {
        display: none
    }
    .account-sandbar{
        display: none
    }

    .navigation-icon {
        display: flex;
    }

    nav {
        padding: 17px;
    }
}

</style>