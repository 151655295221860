import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@views/HomeView.vue'
import RideOfferPage from '@views/RideOfferPage.vue'
import MyRideOffersPage from '@views/MyRideOffersPage.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            mustbedriver: false,
            displayTitle: 'Home'
        }
    },
    {
        path: '/rideoffer',
        name: 'rideoffer',
        component: RideOfferPage,
        meta: {
            mustbedriver: true,
            displayTitle: 'Fahrt bearbeiten'
        }
    },
    {
        path: '/my-rideoffers',
        name: 'myrideoffers',
        component: MyRideOffersPage,
        meta: {
            mustbedriver: true,
            displayTitle: 'Meine Fahrten'
        }
    },
    // ----- DEFAULT ROUTE MUST BE LAST! -----
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        // always redirect to landing page
        beforeEnter: (to, from, next) => {
            next({ path: '/', query: to.query })
        }
    }
]

const router = new createRouter({
    history: createWebHistory(),
    routes
})

export default router
