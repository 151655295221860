<template>
    <div class="auto-search">
        <TextInput :inputid="inputid" :model-value="inputlocation.displayname" @update:model-value="locationInput" @click="openResults($event)" :placeholder="location.placeholder" class="text-input">
            <LoadingEllipsis v-if="isloading" class="spinner"/>
            <ul :id="inputid + '-results'" v-if="resultsopen && resultlist.length > 0">
                <li :id="inputid + '-results-' + idx" v-for="(result, idx) in resultlist" :key="result.displayid" @click="handleSelection(result)">
                    <IconsLocationPin class="icon-location-pin" />
                    <span class="location-text">{{ result.displayname }}</span>
                </li>
            </ul>
        </TextInput>
        <TimePicker class="time-picker" :class="{'marginright' : !this.location.waypoint }" v-model="stoptime" @update:model-value="stoptimeInput"></TimePicker>
    </div>
</template>

<script>
import TextInput from '@elements/TextInput.vue'
import LoadingEllipsis from './elements/LoadingEllipsis.vue'
import TimePicker from '@elements/TimePicker.vue'

import axios from 'axios'
import _ from 'lodash'

const EMIT_CLEAR_LOCATION = 'clear-locationname'
const EMIT_SELECT_LOCATION = 'select-location'
const EMIT_TIME_INPUT = 'stoptime-input'
const EMIT_LOCATION_INPUT = 'location-input'

export default {
    name: 'LocationAutoCompleteSearch',
    emits: [EMIT_CLEAR_LOCATION, EMIT_SELECT_LOCATION, EMIT_TIME_INPUT, EMIT_LOCATION_INPUT],
    inject: [ 'util' ],
    props: {
        inputid: String,
        location: {
            id: Number,
            name: String,
            placeholder: String,
            waypoint: Boolean,
            latitude: Number,
            longitude: Number,
            stoptime: {
                        hours: Number,
                        minutes: Number
                    },
        }
    },
    data() {
        return {
            inputlocation: { displayname: '' },
            resultlist: [],
            isloading: false,
            resultsopen: false,
            stoptime: null
        }
    },
    mounted() {
        this.inputlocation.displayname = this.location.name
        this.stoptime = this.location.stoptime
    },
    methods: {
        searchLocation: _.debounce(function() {
            if (this.inputlocation.displayname.trim().length === 0) {
                this.inputlocation = { displayname: '' }
            }

            if (this.inputlocation.displayname.length <= 1) {
                this.$emit(EMIT_CLEAR_LOCATION)
                return
            }

            // reset additional data
            this.inputlocation = {
                displayname: this.inputlocation.displayname
            }

            this.isloading = true

            let url = this.util.middleware() + '/routing/locations'
            url += '?name=' + this.inputlocation.displayname

            axios.get(url)
            .then(response => {
                this.resultlist = response.data.map(entry => {
                    return {
                        displayid: entry.position.longitude + '' + entry.position.latitude,
                        displayname: entry.name,
                        data: entry
                    }
                })

                this.isloading = false
            })
            .catch(err => {
                this.isloading = false
                this.$toast.error(err)
            })
        }, 400),
        handleSelection: function(result) {
            this.resultlist = []
            this.inputlocation.displayname = result.data.name
            this.$emit(EMIT_SELECT_LOCATION, result.data)
        },
        closeResults() {
            this.resultsopen = false
            document.removeEventListener('click', this.closeResults)
        },
        openResults(event) {
            event.target.select()
            this.resultsopen = true

            if (event) {
                event.stopPropagation()
            }

            // register click-outside event on body
            document.addEventListener('click', this.closeResults)
        },
        locationInput: function(value) {
            this.inputlocation.displayname = value
            this.searchLocation()
            this.$emit(EMIT_LOCATION_INPUT)
        },
        stoptimeInput: function(modelData) {
            this.stoptime = modelData
            this.$emit(EMIT_TIME_INPUT, this.stoptime)
        },
    },
    watch: {
        resultlist: function(value) {
            this.resultsopen = value.length > 0
        // },
        // inputvalue: function() {
        //     this.resultsopen = false
        }
    },
    components: {
        TextInput,
        LoadingEllipsis,
        TimePicker
    }
}
</script>

<style lang="scss" scoped>

.auto-search {
    margin-bottom: 8px;
    display: flex;
    width: 100%;
}
.text-input {
    display: flex;
    order:1;
    flex-grow: 1;
    .spinner {
        position: absolute;
        right: 0;
        top: calc(50% - 6px);
        transform: scale(0.7);
    }

    > ul {
        padding: 8px 0;
        border-radius: 8px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
        background-color: var(--sys-surface);
        position: absolute;
        top: 40px;
        width: 100%;
        box-sizing: border-box;
        list-style-type: none;
        overflow: hidden;
        z-index: 1;

        > li {
            display: flex;
            align-items: center;
            font-size: 16px;
            padding: 12px 0px;
            margin: 0px 12px 0px 12px;
            border-bottom: 1px solid var(--sys-outline-variant);
            color: var(--sys-on-surface);
            > span {
                padding: 0px 16px;
            }

            .icon-location-pin {
                position: absolute;
                width: 24px;
                height: 24px;
            }

            .location-text {
                margin-left: 20px;
            }
        }

        > li:hover {
            background: rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
}


.marginright {
    margin-right: 48px;
}


.time-picker {
    order: 2;
    width: 100px;
    min-width: 100px;
}





@media (max-width: 800px) {

    .text-input {
        display: flex;
        order:1;
        flex-grow: 1;

        // > ul {
        //     width: 340px;
        // }
    }
    .auto-search {
        flex-direction: column;
    }

    .time-picker {
        margin-top: 6px;
    }

}

// @media (max-width: 400px) {
//     .auto-search {
//         flex-direction: column;
//     }

//     .time-picker {
//         margin-top: 6px;
//     }
// }


</style>