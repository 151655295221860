<template>
    <div id="page">

        <div class="content">
            <div class="input-container">
                <RidePointSearch class="ridepoint-search" v-model="rideoffer.ridepoints" @update-ridepoints="updateMap" @location-input="resetError" @stoptime-input="resetError"/>
                <VailidityInput v-model="rideoffer.validity" />
                <div class="note-container">
                    <label for="bemerkung">Bemerkungen (optional)</label>
                    <div class='note'>
                        <textarea id="bemerkung" name="bemerkung" v-model="rideoffer.freetext" placeholder="Bemerkungen"></textarea>
                    </div>
                </div>
                <div class="errorMessage" v-show="errorText">
                    <IconsError class="errorIcon"/>
                    <span class="errorMessageText">{{ errorMessage }}</span>
                </div>
                <div class="action-buttons">
                    <button @click="showCancelPopUp" class="secondary">
                        <!-- <IconsDelete/> -->
                        <span>Abbrechen</span>
                    </button>
                    <button @click="submitRide" class="primary">
                        <IconsCheck/>
                        <span>Speichern</span>
                    </button>
                </div>
            </div>
            <div id="mapContainer" class="map-container">
                <div class="errorMap" v-show="showErrorMap">
                    <IconsError class="errorIcon"/>
                    <span class="errorMessageText">{{ MapErrorText }}</span>
                </div>
            </div>
            <PopUp title="Änderung verwerfen" :open="showAreYouSure" submit-label="Verwerfen" cancel-label="Abbrechen" @close="handleClosePopup">
                <label>Möchtest du die Fahr-Änderungen wirklich verwerfen? Dieser Vorgang kann nicht rückgängig gemacht werden.</label>
            </PopUp>
        </div>
    </div>
</template>

<script>
import "maplibre-gl/dist/maplibre-gl.css"

import axios from 'axios'
import RidePointSearch from '@components/RidePointSearch.vue'
import VailidityInput from '@components/ValidityInput.vue'
import { Map, Marker, AttributionControl, LngLat } from 'maplibre-gl'
import CryptoJS from 'crypto-js'
import IconsError from '../../src/assets/icons/IconsError.vue';
import PopUp from '@elements/PopUp.vue'

const ROUTING_LINE = 'RoutingLineString'

export default {
    name: 'RideOfferPage',
    inject: [ 'util', 'keycloak', 'globalState' ],
    data () {
        return {
            rideoffer: {
                rideid: undefined,
                validity: {
                    onetimedrive: true,
                    onetimedate: new Date(),
                    dateintervals: [],
                    ridedays: {
                        monday: true,
                        tuesday: true,
                        wednesday: true,
                        thursday: true,
                        friday: true,
                        saturday: false,
                        sunday: false
                    }
                },
                ridepoints: [
                    {   id: (Math.floor(Math.random() * Date.now())),
                        name: '',
                        placeholder: 'Start',
                        waypoint: false,
                        latitude: 0,
                        longitude: 0,
                        stoptime: null,
                        marker: null},
                    {   id: (Math.floor(Math.random() * Date.now())),
                        name: '',
                        placeholder: 'Ziel',
                        waypoint: false,
                        latitude: 0,
                        longitude: 0,
                        stoptime: null,
                        marker: null}
                    ],
                freetext: '',
                //fehlt noch als Input in UI
                seats: 2,
                preregistrationTime: 30,
                maxDeviationRadius: 1.5,
                //Ende fehlt noch als Input in UI
            },
            map: null,
            zoom: 12,
            center: undefined,
            url: undefined,
            styleUrl: 'https://tile-gl.highq.de/styles/gf-basic-color/style.json',
            lineColor: '#2f4f4f',
            attribution: undefined,
            bounds: null,
            polyline: undefined,
            markergroup: undefined,
            errorMessage: '',
            errorText: false,
            showAreYouSure: false,
            showErrorMap: false,
            MapErrorText: '',
        }
    },
    async mounted() {
        const promises = []
        promises.push(this.init())
        const loadRideId = this.$route.query.rideId
        if (loadRideId && (loadRideId > 0))  {
            promises.push(this.loadRide(loadRideId))
        }
        await Promise.all(promises)
        .then(() => {
            this.initMarker()
            this.updateMap()
        })
        .catch((err) => {
            this.MapErrorText = 'Fehler Initialisierung: ' + err
            this.showErrorMap = true
        } )
    },
    onBeforeUnmount() {
        if (this.map) {
            this.map.remove();
        }
    },
    computed: {
        config() {
            return this.globalState.config
        },
        driverId() {
            return this.keycloak.driverId
        },
        user() {
            //keycloak-ID sha256 gehasht
            return CryptoJS.SHA256(this.keycloak.instance.tokenParsed.sub).toString()
        },
        openInApp() {
            return this.globalState.openInApp
        }
    },
    methods: {
        async init() {
            return new Promise((res, rej) => {
                if(window.matchMedia){
                    var colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
                    colorSchemeQuery.addEventListener('change', this.updateColorScheme);
                }
                this.setStyleUrl()
                this.showErrorMap = false;
                this.center      = new LngLat(this.config.osmconfig.startlng, this.config.osmconfig.startlat)
                this.url         = this.config.osmconfig.tileserver
                this.attribution = `<a href="${this.config.osmconfig.attributionlink}">${this.config.osmconfig.attribution}</a>`
                try {
                    this.map = new Map({
                        container: 'mapContainer',
                        style: this.styleUrl,
                        zoom: this.zoom,
                        maxZoom: 17,
                        minZoom: 5,
                        center: [ this.config.osmconfig.startlng, this.config.osmconfig.startlat ],
                    })
                    // this.map.addControl(new NavigationControl(), 'top-left')
                    this.map.addControl(new AttributionControl({compact: false, customAttribution: ['<a href="https://maplibre.org/" target="_blank">MapLibre</a>', this.attribution]}))
                    // disable map rotation using right click + drag
                    this.map.dragRotate.disable();
                    // disable map rotation using touch rotation gesture
                    this.map.touchZoomRotate.disableRotation();
                    if (this.openInApp) {
                        //slow down Zooming in AppView (fast zoom crashes app but only in AppView)
                        //do these settings have any affect on twofinger touch zoom?
                        this.map.scrollZoom.setZoomRate(1/1500);
                        this.map.scrollZoom.setWheelZoomRate(1/1500);
                    }
                    this.map.on('load', () => res())
                } catch(err) {
                    this.showErrorMap = true;
                    this.MapErrorText = 'init Map - ' + err
                    rej(err)
                }
            })

        },
        setStyleUrl(){
            if (window.matchMedia) {
                if(window.matchMedia('(prefers-color-scheme: dark)').matches){
                    this.styleUrl = 'https://tile-gl.highq.de/styles/gf-basic-color-dark/style.json'
                    this.lineColor = '#a2f799'
                } else {
                    this.styleUrl = 'https://tile-gl.highq.de/styles/gf-basic-color/style.json'
                    this.lineColor = '#2f4f4f'
                }
            } else {
                this.styleUrl = 'https://tile-gl.highq.de/styles/gf-basic-color/style.json'
                this.lineColor = '#2f4f4f'
            }
        },
        updateColorScheme() {
            this.setStyleUrl()
            this.map.setStyle(this.styleUrl)
            this.map.getLayer(ROUTING_LINE).paint.lineColor = this.lineColor
        },
        initMarker() {
            this.rideoffer.ridepoints?.forEach(function(point) {
                //Wenn Koordinaten und Name hinterlegt, dann Marker anlegen
                if (point.name && ((point.longitude > 0) || (point.latitude > 0))) {
                    //Marker für Karte definieren
                    let position = new LngLat(point.longitude, point.latitude)
                    let marker = {
                        display: point.name,
                        position: position
                    }
                    point.marker = marker
                }
            })
        },
        updateMap(){
            this.showErrorMap = false;
            let listmarker = []
            let listposition = []
            //alle Marker der Ridepoints auslesen
            this.rideoffer.ridepoints.forEach(function(point) {
                    if (point.marker) {
                        listmarker.push(point.marker)
                        listposition.push(point.marker.position)
                    }
                })
            //maplibre
            //alle vorhandenen Marker entfernen.
            if (this.markergroup) {
                this.markergroup.forEach(oldmarker => {
                    oldmarker.remove();
                })
            }
            this.markergroup = []
            //Marker neu zeichnen
            if (listmarker) {
                listmarker.forEach(marker => {
                    let newMarker = new Marker()
                    // new Marker()
                    .setLngLat(marker.position)
                    .addTo(this.map);
                    this.markergroup.push(newMarker);
                }
                )
            }
            //Kartenausschnitt setzen
            try {
                if (listmarker && listmarker.length == 1) {
                    //Nur ein Marker, dann diesen mittig
                    this.map.setCenter(listmarker[0].position)
                } else if (listmarker && listmarker.length > 1) {
                    //Mehrere Marker, dann Bounds verwenden
                    this.bounds = [listposition[0], listposition[listposition.length -1]]

                    let speedFit = 0.8
                    if (this.openInApp) {
                        //set speed down for fitBounds in AppView (fast zoom crashes app but only in AppView)
                        speedFit = 0.2
                    }

                    this.map.fitBounds(this.bounds, {
                            speed: speedFit,
                            padding: 100
                        })
                } else {
                    this.bounds= null
                }
                //Polyline setzen
                if (listposition.length > 1) {
                    this.updateWayOnMap()
                }
            } catch(err) {
                this.showErrorMap = true;
                this.MapErrorText = 'updateMap - Fehler beim Anzeigen/Laden der Karte aufgetreten' + err
            }
        },
        mapRideBackendFrontend(ride) {
            let rideoffer = { validity: {} }
            rideoffer.rideid = ride.rideID
            rideoffer.validity.dateintervals = []
            //Gültigkeiten übertragen
            if (ride.validityPeriod.length === 1 && ride.validityPeriod[0].validFrom === ride.validityPeriod[0].validUntil) {
                rideoffer.validity.onetimedrive = true
                rideoffer.validity.onetimedate = new Date(ride.validityPeriod[0].validFrom)
                rideoffer.validity.dateintervals = []
            } else {
                rideoffer.validity.onetimedrive = false
                rideoffer.validity.onetimedate = new Date()
                ride.validityPeriod.forEach(function(interval) {
                    let newInterval = {
                            id: (Math.floor(Math.random() * Date.now())),
                            dateFrom: new Date(interval.validFrom),
                            dateUntil: new Date(interval.validUntil)
                    }
                    rideoffer.validity.dateintervals.push(newInterval)
                })
            }
            rideoffer.validity.ridedays = ride.rideDays
            rideoffer.freetext = ride.note
            rideoffer.seats    = ride.seats
            rideoffer.preregistrationTime = ride.preregistrationTime
            rideoffer.maxDeviationRadius  = ride.maxDeviationRadius
            rideoffer.ridepoints =[]
            // //Ridepoints übertragen
            ride.ridePoints.forEach(function(inputpoint, index) {
                let point = {}
                point.id = (Math.floor(Math.random() * Date.now()))
                point.name = inputpoint.name
                point.latitude = inputpoint.latitude
                point.longitude = inputpoint.longitude
                point.marker = {
                    display: inputpoint.name,
                    position: new LngLat(point.longitude, point.latitude)
                }
                const date = new Date(inputpoint.stopTime);
                point.stoptime = {
                                     hours: date.getHours(),
                                     minutes: date.getMinutes()
                                 }
                if (index === 0) {
                    point.placeholder = 'Start'
                    point.waypoint = false
                } else if (index === ride.ridePoints.length -1) {
                    point.placeholder = 'Ziel'
                    point.waypoint = false
                } else {
                    point.placeholder = 'Wegpunkt'
                    point.waypoint = true
                }

               rideoffer.ridepoints.push(point)
            })
            return rideoffer
        },
        async loadRide(loadId) {
            return new Promise((res, rej) => {
                let url = this.util.middleware() + '/rideproviders'
                url += `/${this.driverId}` + '/rideoffers/' + loadId
                axios.get(url)
                .then(result => {
                    this.rideoffer = this.mapRideBackendFrontend(result.data)
                    res()
                })
                .catch(err => {
                    rej(err)
                })
            })
        },
        getDefaultLineGeoJson() {
            return {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            properties: {},
                            coordinates: []
                        }
                    }
                ]
            }
        },
        registerRoutingLine() {
            // register routing line and layer
            this.map.addSource(ROUTING_LINE, {
                type: 'geojson',
                data: this.getDefaultLineGeoJson()
            })

            this.map.addLayer({
                id: ROUTING_LINE,
                type: 'line',
                source: ROUTING_LINE,
                paint: {
                    'line-color': this.lineColor,
                    'line-width': 3
                }
            })
        },
        newPolyLine: function(event) {
            //maplibre
            try {
            let updatedGeoJson = this.getDefaultLineGeoJson();
            if (event.length > 1) {
                let coordinatesMap = event.map((coord) => [coord.lng, coord.lat])
                updatedGeoJson.features.at(0).geometry.coordinates = coordinatesMap
            }
            if (!this.map.getSource(ROUTING_LINE)) {
                this.registerRoutingLine()
            }
            this.map.getSource(ROUTING_LINE).setData(updatedGeoJson)
            } catch(err) {
                this.showErrorMap = true;
                this.MapErrorText = 'newPolyLine - Fehler beim Anzeigen/Laden der Karte aufgetreten' + err
            }
        },
        updateWayOnMap: function() {
            this.showErrorMap = false;
            //nur wenn mindestens zwei Punkte enthalten
            if (this.rideoffer.ridepoints.length > 1) {
                //alle Punkte mit Koordinaten sammeln
                let polygonReq = []
                this.rideoffer.ridepoints.forEach(point => {
                    if ((point.latitude != 0) || (point.longitude != 0)) {
                    polygonReq.push({longitude: point.longitude, latitude: point.latitude})
                    }
                })
                //Wenn mind zwei Punkte mit Koordinaten, dann an Routing schicken
                if (polygonReq.length > 1) {
                    this.routingViaORS(polygonReq)
                }
            }
        },
        routingViaORS(polygonReq) {
            let urlRouting = this.util.middleware() + '/routing/polygon'
            axios.post(urlRouting, polygonReq)
            .then(response => {
                if(response.status === 200) {
                    //gerouteten Weg auf Karte anzeigen
                    let coordinates = []
                    response.data?.polygon.forEach(position => {
                        coordinates.push(new LngLat(position.longitude, position.latitude))
                    })
                    this.newPolyLine(coordinates)
                } else if (polygonReq.length == 2) {
                    this.routingViaTrias(polygonReq)
                } else {
                    this.showErrorMap = true;
                    this.MapErrorText = 'Fehler beim Routen der Strecke.'
                    this.beeLine()
                }
            })
            .catch(() => {
                if (polygonReq.length == 2) {
                    this.routingViaTrias(polygonReq)
                } else {
                    this.showErrorMap = true;
                    this.MapErrorText = 'Fehler beim Routen der Strecke.'
                    this.beeLine()
                }
            })
        },
        routingViaTrias(polygonReq) {
            let urlRouting = this.util.middleware() + '/routing/trias/polygon'
            axios.post(urlRouting, polygonReq)
            .then(response => {
                if(response.status === 200) {
                    //gerouteten Weg auf Karte anzeigen
                    let coordinates = []
                    response.data?.polygon.forEach(position => {
                        coordinates.push(new LngLat(position.longitude, position.latitude))
                    })
                    this.newPolyLine(coordinates)
                } else {
                    this.showErrorMap = true;
                    this.MapErrorText = 'Fehler beim Routen der Strecke.'
                    this.beeLine()
                }
            })
            .catch(err => {
                this.showErrorMap = true;
                this.MapErrorText = 'Fehler beim Routen der Strecke. ' + err
                this.beeLine()
            })
        },
        beeLine() {
            let coordinates = []
            //alle Marker der Ridepoints auslesen
            this.rideoffer.ridepoints.forEach(function(point) {
                    if (point.marker) {
                        coordinates.push(point.marker.position)
                    }
                })
            this.newPolyLine(coordinates)
        },
        checkRideofferInput() {
            // this.validator.$validate()

            // if (this.validator.$invalid) {
            //     console.log('Validation invalid')
            //     return false
            // }
            // console.log('Validation ok')
            // return true

            if (this.rideoffer.ridepoints.length < 2) {
                this.errorText = true;
                this.errorMessage = 'Es müssen mindestens zwei Wegpunkte (von/nach) angegeben sein';
                return false;
            }
            else {
                for (let idx = 0; idx < this.rideoffer.ridepoints.length; idx++) {
                    if (this.rideoffer.ridepoints[idx].name == '' || ((this.rideoffer.ridepoints[idx].latitude == 0) || (this.rideoffer.ridepoints[idx].longitude == 0))) {
                        if (idx == 0) {
                            this.errorMessage = 'Fehlerhafter Start-Ort. Bitte wähle einen gültigen Start-Ort';
                        } else if (idx == this.rideoffer.ridepoints.length -1) {
                            this.errorMessage = 'Fehlerhafter Ziel-Ort. Bitte wähle einen gültigen Ziel-Ort';
                        } else {
                            this.errorMessage = 'Fehlerhafter Wegpunkt (' + idx + '). Bitte wähle einen gültigen Wegpunkt-Ort. Wird er nicht benötigt bitte entfernen.';
                        }
                        this.errorText = true;
                        return false;
                    }
                    else if (!this.rideoffer.ridepoints[idx].stoptime || this.rideoffer.ridepoints[idx].stoptime.hours == null || this.rideoffer.ridepoints[idx].stoptime.minutes == null) {
                        if (idx == 0) {
                            this.errorMessage = 'Abfahrtszeit darf nicht leer sein.';
                        } else if (idx == this.rideoffer.ridepoints.length -1) {
                            this.errorMessage = 'Geschätzte Ankunftszeit darf nicht leer sein.';
                        } else {
                            this.errorMessage = 'Geschätzte Ankunftszeit am Wegpunkt (' + idx + ') darf nicht leer sein.';
                        }
                        this.errorText = true;
                        return false;
                    }
                    else if (idx > 0) {//aufeinanderfolgende Punkte sollten nicht gleich sein
                        if (this.rideoffer.ridepoints[idx-1].name == this.rideoffer.ridepoints[idx].name && this.rideoffer.ridepoints[idx-1].latitude == this.rideoffer.ridepoints[idx].latitude && this.rideoffer.ridepoints[idx-1].longitude == this.rideoffer.ridepoints[idx].longitude) {
                            this.errorMessage = 'Aufeinanderfolgende Orte dürfen nicht gleich sein';
                            this.errorText = true;
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        submitRide: function()  {
            //TODO Prüfung umstellen auf vuelidate
            this.resetError()
            if (this.checkRideofferInput())  {
                console.log('Validation ok')
                let rideRequest ={}
                //IDs erstmal fix, muss später über Anmeldeprozess ergänzt werden
                rideRequest.providerID = this.driverId

                rideRequest.userID = this.user
                rideRequest.ridePoints = []
                this.rideoffer.ridepoints.forEach(function(point) {
                    let timeInIso= new Date()
                    timeInIso.setSeconds(0)
                    timeInIso.setMinutes(point.stoptime.minutes)
                    timeInIso.setHours(point.stoptime.hours)
                    let timestring = timeInIso.toISOString()
                    rideRequest.ridePoints.push({longitude: point.longitude, latitude: point.latitude, name: point.name, stopTime: timestring})
                })
                if (this.rideoffer.validity.onetimedrive) {
                    rideRequest.validityPeriod = [{validFrom: this.rideoffer.validity.onetimedate.toISOString(), validUntil: this.rideoffer.validity.onetimedate.toISOString()}]
                    let dayOfWeek = this.rideoffer.validity.onetimedate.getDay()
                    rideRequest.rideDays = this.rideoffer.validity.ridedays
                    rideRequest.rideDays.monday    = (dayOfWeek ===1)
                    rideRequest.rideDays.tuesday   = (dayOfWeek ===2)
                    rideRequest.rideDays.wednesday = (dayOfWeek ===3)
                    rideRequest.rideDays.thursday  = (dayOfWeek ===4)
                    rideRequest.rideDays.friday    = (dayOfWeek ===5)
                    rideRequest.rideDays.saturday  = (dayOfWeek ===6)
                    rideRequest.rideDays.sunday    = (dayOfWeek ===0)
                } else {
                    //Intervall aufnehmen, sobald über Oberfläche pflegbar
                    rideRequest.validityPeriod = []
                    this.rideoffer.validity.dateintervals.forEach(function(interval) {
                                rideRequest.validityPeriod.push({validFrom: interval.dateFrom.toISOString(), validUntil: interval.dateUntil.toISOString()})
                                })
                    rideRequest.rideDays = this.rideoffer.validity.ridedays
                }

                rideRequest.seats = this.rideoffer.seats
                rideRequest.preregistrationTime = this.rideoffer.preregistrationTime
                rideRequest.maxDeviationRadius = this.rideoffer.maxDeviationRadius
                rideRequest.note = this.rideoffer.freetext

                if (this.rideoffer.rideid) {
                    rideRequest.rideID = this.rideoffer.rideid
                    let url = this.util.middleware() + '/rideproviders'
                    url += `/${this.driverId}` + '/rideoffers'
                    url += `/${rideRequest.rideID}`
                    axios.put(url, rideRequest)
                    .then(response => {
                        if(response.status === 200) {
                            this.$toast.success('Fahrt-Angebot erfolgreich gespeichert')
                            this.$router.push('/my-rideoffers')
                        } else {
                            this.errorText = true;
                            this.errorMessage = 'Fehler beim Speichern des Fahrt-Angebots';
                        }
                    })
                    .catch(err => {
                        this.errorText = true;
                        this.errorMessage = 'Fehler beim Speichern des Fahrt-Angebots';
                        this.$toast.error(err)
                    })

                } else {
                    let url = this.util.middleware() + '/rideproviders'
                    url += `/${this.driverId}` + '/rideoffers'
                    axios.post(url, rideRequest)
                    .then(response => {
                        if(response.status === 200) {
                            this.$toast.success('Fahrt-Angebot erfolgreich gespeichert')
                            this.$router.push('/my-rideoffers')
                        } else {
                            this.errorText = true;
                            this.errorMessage = 'Fehler beim Speichern des Fahrt-Angebots';
                        }
                    })
                    .catch(err => {
                        this.errorText = true;
                        this.errorMessage = 'Fehler beim Speichern des Fahrt-Angebots';
                        this.$toast.error(err)
                    })
                }
            }

        },
        resetError: function() {
            this.errorText = false;
            this.errorMessage = '';
        },
        showCancelPopUp() {
            this.showAreYouSure = true
        },
        handleClosePopup(event) {
            this.showAreYouSure = false
            if (event) {
                if (this.rideoffer.rideid) {
                    //Fahrt bearbeitet --> Zurück zur Fahrten-Übersicht
                    this.$router.push('/my-rideoffers')
                } else {
                    //Neue Fahrt --> zurück zur Start-Seite
                    this.$router.push('/')
                }
            }
        },
    },
    components: {
        RidePointSearch,
        VailidityInput,
        IconsError,
        PopUp
    }
}

</script>

<style  lang="scss" scoped>
.input-container {
    width: 37%;
    float: left;
    padding:5px 20px 20px 40px;

    :deep(input[type="text"]),
    :deep(input[type="time"]),
    :deep(input[type="date"]) {
        height: 40px;
        color: var(--sys-on-surface);
        font-size: 16px;
        padding: 11px 10px 10px;
        border-radius: 4px;
        border: solid 1px var(--sys-outline);
        background-color: var(--sys-surface-bright);
    }


    :deep(p),
    :deep(label){
        display:block;
        font-size: 14px;
        font-weight: 600;
        color: var(--sys-on-surface);
    }

    :deep(.plusbutton) {
        background-color: var(--buttoncolor, #fff) ;
        text-align: center;
        font-style: normal;
        font-size: 28px;
        border-radius: 16px;
        width: 32px;
        height: 32px;
        display: inline-block;
    }

    :deep(.plusbuttonlabel) {
        font-size: 14px;
        margin-left: 10px;
        // padding-bottom: 50px;
    }

    .action-buttons{
        display: flex;
        gap: 8px;
    }


}

.errorMessage {
    display: flex;
    align-items: center;
    margin-top: 12px;
    font-size: 14px;
    font-weight: 600;
}

.errorMessageText {
    color: var(--sys-error);
    margin-left: 5px;
}

.errorIcon {
    color: var(--sys-on-surface);
}

.errorMap {
    display:flex;
    width: 50%;
    align-items: center;
    gap: 8px;
    position: relative;
    left: 64px;
    top: 16px;
    z-index: 2000;
    padding: 8px 8px 8px 12px;
    border-radius: 18px;
    box-shadow: 0 3px 6px 0 var(--sys-shadow);
    background-color: var(--sys-surface);
    color: var(--sys-error);
    font-size: 14px;
    font-weight: 600;
}

.map-container {
    margin-top: 37px;
    float: right;
    right: 37px;
    width: 58%;
    height: 556px;
    z-index: 0;
    //test zoom-crash in App WebView
    //transition: none;
}

.note-container{
    margin-top: 20px;

    // .note:hover {
    //     border: 1px solid var(--framehovercolor, #064A6C);
    // }

    .note{

        display: flex;
        margin-right: 48px;

        textarea{
            width: 100%;
            height: 100px;
            margin: 4px 0 0;
            padding: 11px 12px;
            border-radius: 4px;
            border: solid 1px var(--sys-outline);
            background-color: var(--sys-surface-bright);
            font-size: 16px;
            color: var(--sys-on-surface-variant);
        }
    }
}


.ridepoint-search{
    margin-top: 50px;
}




@media (max-width: 1400px) {
    .map-container{
        margin-top: 0px;
        display: block;
        float: left;
        left: 8px;
        height: 400px;
        width: 98%;
    }


    .input-container{
        display: block;
        float: left;
        width: 100%; /* The width is 100%, when the viewport is 1050px or smaller */
        padding:5px 15px 15px 15px;
    }

}



@media (max-width: 800px) {
    .input-container {
        :deep(input[type="text"]),
        :deep(input[type="button"]),
        :deep(input[type="time"]),
        :deep(input[type="date"]) {
            font-size: 1em;
        }
    }

    .ridepoint-search{
        margin-top: 10px;
    }

    .note-container{

        .note{
            margin-right: 0px;
        }
}
}


</style>