<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m13.3 14.7 1.4-1.4L11 9.6V5H9v5.4l4.3 4.3zM10 20a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 0 10c0-1.383.263-2.683.787-3.9a10.099 10.099 0 0 1 2.138-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 10 0c1.383 0 2.683.263 3.9.787a10.099 10.099 0 0 1 3.175 2.138c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 20 10a9.738 9.738 0 0 1-.788 3.9 10.099 10.099 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 10 20zm0-2c2.217 0 4.104-.78 5.662-2.338C17.221 14.104 18 12.217 18 10s-.78-4.104-2.338-5.662C14.104 2.779 12.217 2 10 2s-4.104.78-5.662 2.338C2.779 5.896 2 7.783 2 10s.78 4.104 2.338 5.662C5.896 17.221 7.783 18 10 18z"
            fill="currentColor"
            fill-rule="nonzero"
        />
    </svg>
</template>
