<template>
    <div id="page">
        <div class="content">
            <div v-if="myrides.length > 0" class="ride-container">
                <div class="ride-table-view">
                    <table class="ride-table">
                        <tr>
                            <th class="location">Start</th>
                            <th class="time">Startzeit</th>
                            <th class="location">Ziel</th>
                            <th class="time">Zielzeit</th>
                            <th class="validity">Gültigkeit</th>
                            <th class="weekdays">Wochentage</th>
                            <th class="action"></th>
                        </tr>
                        <tr v-for="(ride, index) in myrides" :key="index">
                            <td>{{ ride.ridePoints[0].name }}</td>
                            <td>{{ getTimeString(ride.ridePoints[0].stopTime) }} Uhr</td>
                            <td>{{ ride.ridePoints[ride.ridePoints.length - 1].name }}</td>
                            <td>{{ getTimeString(ride.ridePoints[ride.ridePoints.length - 1].stopTime) }} Uhr</td>
                            <td>{{ getValidityString(ride) }}</td>
                            <td>{{ getWeekdayString(ride.rideDays) }}</td>
                            <td class="ellipsis">
                                <div id="app">
                                    <div @click="toggleDropdown(index)">
                                        <IconsEllipsis/>
                                    </div>
                                    <div class="dropdown-content" v-show="selectedDropdownIndex === index">
                                        <div class="dropdown-item"  @click="changeRide(ride)">
                                            <label>Bearbeiten</label>
                                            <IconsEdit class="icon"/>
                                        </div>
                                        <div class="dropdown-item" @click="showDeletePopUp(ride)">
                                            <label>Löschen</label>
                                            <IconsDelete class="icon"/>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="ride-item-view">
                    <div class="ride-item" v-for="(ride, index) in myrides" :key="index">
                        <table class="info-table">
                            <tr>
                                <td class="info-title">Start</td>
                            </tr>
                            <tr>
                                <td class="info-value"> <p>{{ ride.ridePoints[0].name }}</p></td>
                                <td class="info-value"> <p>{{ getTimeString(ride.ridePoints[0].stopTime) }} Uhr</p></td>
                            </tr>
                            <tr>
                                <td class="info-title">Ziel</td>
                            </tr>
                            <tr>
                                <td class="info-value"> <p>{{ ride.ridePoints[ride.ridePoints.length - 1].name }}</p></td>
                                <td class="info-value"> <p>{{ getTimeString(ride.ridePoints[ride.ridePoints.length - 1].stopTime) }} Uhr</p></td>
                            </tr>
                            <tr>
                                <td class="info-title">Wochentage</td>
                            </tr>
                            <tr>
                                <td class="info-value"> <p>{{ getWeekdayString(ride.rideDays) }}</p></td>
                            </tr>
                            <tr>
                                <td class="info-title">Gültigkeit</td>
                            </tr>
                            <tr>
                                <td class="info-value"> <p class="max-two-lines">{{ getValidityString(ride) }}</p></td>
                            </tr>
                        </table>
                        <div class="item-buttons">
                            <button @click="showDeletePopUp(ride)" class="secondary item-button">
                                <IconsDelete/>
                                <span>Löschen</span>
                            </button>
                            <button @click="changeRide(ride)" class="primary item-button">
                                <IconsEdit/>
                                <span>Bearbeiten</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="no-ride">
                <div class="info-text">
                    <label> Du hast noch keine Fahrten eingetragen. Beginne jetzt mit deiner ersten Fahrt.</label>
                </div>
                <div class="new-ride">
                    <label class="new-ride-title">Neue Fahrt</label>
                    <p class="new-ride-text">Erstelle eine neue Mitfahrt</p>
                    <button @click="goToNewRideOffer" class="primary">
                        <IconsAdd/>
                        <span>Erstellen</span>
                    </button>
                </div>
            </div>
        </div>

        <PopUp title="Fahrt löschen" :open="showAreYouSure" submit-label="Löschen" cancel-label="Abbrechen" @close="handleClosePopup">
            <label>Möchtest du die Fahrt wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.</label>
        </PopUp>
    </div>
</template>

<script>
import axios from 'axios'
import PopUp from '@elements/PopUp.vue'

export default {
    name: 'my-ride-offers-page',
    inject: ['util', 'keycloak', 'globalState'],
    data() {
        return {
            showAreYouSure: false,
            submitRide: undefined,
            myrides: [],
            selectedDropdownIndex: -1,

        };
    },
    mounted() {
        this.loadRides();
    },
    computed: {
        driverId() {
            return this.keycloak.driverId;
        }
    },
    methods: {
        loadRides() {
            let url = this.util.middleware() + '/rideproviders';
            url += `/${this.driverId}` + '/rideoffers';
            axios.get(url)
                .then(result => {
                this.myrides = result.data;
            })
                .catch(err => {
                console.log('BACKEND UNREACHABLE ' + err);
            });
        },
        getTimeString(isostring) {
            const date = new Date(isostring);
            return date.toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' });
        },
        getValidityString(ride) {
            //prüfen, ob Fahrt für einen Tag, oder regelmäßig
            if (ride.validityPeriod.length === 1 && ride.validityPeriod[0].validFrom === ride.validityPeriod[0].validUntil) {
                let date = new Date(ride.validityPeriod[0].validFrom);
                return date.toLocaleDateString();
            }
            else if (ride.validityPeriod.length === 0) {
                return ' unbegrenzt ';
            }
            else {
                return this.getIntervalString(ride.validityPeriod);
            }
        },
        getWeekdayString(weekdays) {
            let weekdaystr = '';
            if (weekdays.monday) {
                weekdaystr += ' Mo';
            }
            if (weekdays.tuesday) {
                weekdaystr += ' Di';
            }
            if (weekdays.wednesday) {
                weekdaystr += ' Mi';
            }
            if (weekdays.thursday) {
                weekdaystr += ' Do';
            }
            if (weekdays.friday) {
                weekdaystr += ' Fr';
            }
            if (weekdays.saturday) {
                weekdaystr += ' Sa';
            }
            if (weekdays.sunday) {
                weekdaystr += ' So';
            }
            return weekdaystr;
        },
        getIntervalString(validityintervals) {
            let intervalstr = '';
            validityintervals.forEach(function (interval) {
                if (intervalstr != '') {
                    intervalstr += '\n ';
                }
                let dateFrom = new Date(interval.validFrom);
                let dateUntil = new Date(interval.validUntil);
                intervalstr += dateFrom.toLocaleDateString() + ' - ' + dateUntil.toLocaleDateString();
            });
            return intervalstr;
        },
        changeRide(ride) {
            this.$router.replace({ name: 'rideoffer', query: { rideId: ride.rideID } }); //, params: ridefrontend })
        },
        showDeletePopUp(ride) {
            if (ride) {
                this.submitRide = ride
                this.showAreYouSure = true
            }
        },
        handleClosePopup(event) {
            if (event) {
                this.deleteRide(this.submitRide)
            } else {
                this.submitRide = undefined
            }
            this.showAreYouSure = false
        },
        deleteRide(ride) {
            let url = this.util.middleware() + '/rideproviders';
            url += `/${this.driverId}` + '/rideoffers';
            url += `/${ride.rideID}`;
            axios.delete(url)
                .then(response => {
                if (response.status === 200) {
                    this.loadRides();
                    this.$toast.success('Fahrt wurde gelöscht');
                }
                else {
                    this.$toast.error('Fehler beim Löschen der Fahrt');
                }
            })
                .catch(err => {
                this.$toast.error(err);
            });
        },
        toggleDropdown(index) {
            this.selectedDropdownIndex = this.selectedDropdownIndex === index ? -1 : index;
        },
        goToNewRideOffer() {
            this.$router.push('/rideoffer')
        }
    },
    components: {
        PopUp
    }

}
</script>

<style lang="scss" scoped>


.ride-container {
    margin: 20px;
    // Kacheln
    .ride-item-view {
        display: none;
        >.ride-item {
            display: inline-block;
            border: 0px;
            border-radius: 8px;
            margin: 0px 20px 20px 0px;
            // padding: 10px 16px 16px 16px;
            padding: 20px 19.1px 19px 17.8px;
            width: 374px;
            box-shadow: 0 3px 6px 0 var(--sys-shadow);
            background-color: var(--sys-surface);

            .info-table {
                // width: 330px;
                width: 100%;
                height: 275px;
                font-size: 16px;
                td:nth-child(2) {
                    width: 84px;
                    padding-left: 5px;
                    font-weight: normal;
                    float: right;
                }
                tr:last-child {
                    height:100%;
                }
                tr {
                    height: 1.3em;
                    >.info-value {
                        vertical-align: top;
                        color: var(--sys-on-surface);

                        :deep(p) {
                            white-space: pre-line;
                            display: -webkit-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                        >.max-two-lines{
                            -webkit-line-clamp: 2;
                        }
                    }
                    >.info-title{
                        vertical-align: bottom;
                        font-weight: 500;
                        font-size: 12px;
                        font-weight: 600;
                        color: var(--sys-on-surface);
                    }
                }
            }
            .item-buttons{
                display: flex;
                gap: 16px;
                .item-button{
                    margin-bottom: 0px;
                }
            }
        }
    }

    // Tabelle
    .ride-table-view{
        width: 100%;

        .ride-table {
            width: 100%;
            min-height: 400px;
            table-layout: fixed;
            border-collapse: collapse;
            border-radius: 8px;
            box-sizing: border-box;
            font-size: 16px;
            box-shadow: 0 3px 6px 0 var(--sys-shadow);
            background-color: var(--sys-surface);
            color: var(--sys-on-surface);

            th {
                border-bottom: 1px solid var(--sys-outline-variant, #e3e3e3);
                padding-top: 20px;
                padding-bottom: 8px;
                text-align: left;
                font-size: 14px;
                padding-left: 20px;
            }
            th.time {
              width: 100px;
            }
            th.validity {
              width: 300px;
            }
            th.weekdays {
              width: 200px;
            }
            th.action {
              width: 50px;
            }

            td {
                padding-left: 20px;
                padding-top: 12px;
                padding-bottom: 8px;
                max-width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            tr {
                vertical-align:top;
            }

            tr:last-child {
                height:100%;
                > td {
                    padding-bottom: 12px;
                } 
            }            

            tr:last-child 
             

            th:first-child,
            td:first-child {
                padding-left: 20px;
            }

            th:last-child,
            td:last-child {
                padding-right: 50px;
            }

            .ellipsis{
                .icons-ellipsis {
                    cursor: pointer;
                }
                .dropdown-content {
                    position: absolute;
                    width: 236px;
                    height: 112px;
                    border-radius: 8px;
                    padding-top: 4px;
                    box-shadow: 0 3px 6px 0 var(--sys-shadow);
                    background-color: var(--sys-surface);
                    z-index: 1;
                    right: 44px;

                    .dropdown-item{
                        margin: 0px 16px;
                        padding: 15px 0px;
                        border-bottom: solid 1px var(--sys-outline-variant);
                        :deep(label) {
                            font-size: 14px;
                            font-weight: 600;
                        }
                        :deep(.icon) {
                            position: absolute;
                            right: 15px;
                        }
                        :deep(label:hover) {
                            cursor: pointer;
                        }
                    }
                    .dropdown-item:hover{
                        cursor: pointer;
                    }
                }                
            }
        }
    }
}

.no-ride{
    .info-text{
        margin: 40px;
        font-size:16px;
        color: var(--sys-on-surface);
    }

    .new-ride {
        display: inline-block;
        width: 330px;
        height: 165px;
        margin: 0px 30px 0px 40px;
        padding: 24px;
        border-radius: 18px;
        box-shadow: 0 3px 6px 0 var(--sys-shadow);
        background-color: var(--sys-surface);

        .new-ride-title {
            display: block;
            height: 25px;
            font-size: 22px;
            font-weight: 500;
            margin: 0 0 4px;
            font-size: 22px;
            font-weight: 600;
            color: var(--sys-on-surface);
        }

        .new-ride-text {
            display: block;
            height: 32px;
            margin: 4px 0px;
            font-size: 14px;
            color: var(--sys-on-surface);
        }

        .link {
            width: 128px;
            height: 40px;
            margin: 16px 0px;
            padding: 11px 10px;
            border-radius: 8px;
            border: 0px;
            color: var(--sys-on-primary);
            background-color: var(--sys-primary);

            :deep(img) {
                vertical-align: middle;
                display: inline-block;
                margin-right: 6px;
                width: 18px;
                height: 18px;
            }
            :deep(span) {
                vertical-align: middle;
            }
        }
    }
}


@media (max-width: 750px) {
    .ride-container {
        display: inline-block;
        .ride-item-view {
            >.ride-item {
                display: block;
                width: 100%;
                margin: 0px 8px 8px 0px;
            }
        }
    }
}

@media (max-width: 1100px) {
    .ride-container {
        .ride-item-view {
            display: block;
        }
        .ride-table-view {
            display: none;
        }
    }
    .no-ride{
        .info-text{
            margin: 16px;
    }
    .new-ride {
        margin: 0px 20px 20px 16px;
    }

    }

}


</style>