<template>
    <div class="date-interval-input">
        <div class="date-box">
            <label for="dateVon">Von</label>
            <DatePicker id="datevon" name="datevon" class="date-picker" v-model="inpDateFrom"  @update:model-value="handleFromInput"></DatePicker>
        </div>
        <div class="date-box">
            <label for="datebis">Bis</label>
            <DatePicker id="datebis" name="datebis" class="date-picker" v-model="inpDateUntil"  @update:model-value="handleUntilInput"></DatePicker>
        </div>
        <button class="secondary round remove" name="removebutton" value="x" @click="removeInterval">
            <IconsDelete/>
        </button>
    </div>
</template>

<script>
import DatePicker from '@elements/DatePicker.vue'

const EMIT_REMOVE_INTERVAL = 'remove-interval'
const EMIT_DATEFROM_INPUT = 'date-from-input'
const EMIT_DATEUNTIL_INPUT = 'date-until-input'


export default {
    name: 'date-interval-input',
    emits: [ EMIT_REMOVE_INTERVAL, EMIT_DATEFROM_INPUT, EMIT_DATEUNTIL_INPUT],
    props: {
        intervalIndex: Number,
        interval: {
            dateFrom: new Date(),
            dateUntil: new Date(),
        }
    },
    data() {
        return {
            inpDateFrom: new Date(),
            inpDateUntil: new Date()
        }
    },
    mounted() {
        this.inpDateFrom = this.interval.dateFrom
        this.inpDateUntil = this.interval.dateUntil
    },
    methods: {
        handleFromInput: function (value) {
            this.$emit(EMIT_DATEFROM_INPUT, value, this.intervalIndex);
        },
        handleUntilInput: function (value) {
            this.$emit(EMIT_DATEUNTIL_INPUT, value, this.intervalIndex);
        },
        removeInterval: function () {
            this.$emit(EMIT_REMOVE_INTERVAL, this.intervalIndex);
        }
    },
    components: {
        DatePicker
    }
}
</script>

<style lang="scss" scoped>

.date-interval-input {
    display: flex;
    gap: 8px;

    .remove {
        padding: 10px;
        margin-top: 20px;
    }

    .date-box {
        display: inline;
        margin-bottom: 12px;

        label{
            margin: 0px 0px 4px 8px;
        }
        .date-input {
            width: 150px;
        }
    }
}



// @media (max-width: 800px) {


//     .date-input {
//         width: 140px;
//         margin: 5px 10px 5px 0px;
//     }
//     .date-box {
//         :deep(label){
//             margin-right: 10px;
//         }
//     }

// }

// @media (max-width: 400px) {


//     .date-input {
//         width: 120px;
//     }
// }

</style>