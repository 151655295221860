import App from './App.vue'
import util from '@plugins/util.js'
import axios from 'axios'
import router from './router'
import appIcons from './assets/icons/app-icons'
import ToastPlugin from 'vue-toast-notification'
import VueDatePicker from '@vuepic/vue-datepicker';
import { createApp, reactive } from 'vue'
import { connectKeycloak } from '@plugins/keycloak.js'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue-toast-notification/dist/theme-default.css'

const app = createApp(App)

const keycloakState = reactive({
    available:  false,
    instance:   undefined,
    driverId:   undefined
})

const globalState = reactive({
    config: undefined,
    openInApp: false
})

async function setup() {
    app.provide('util',        util)
    app.provide('globalState', globalState)
    app.provide('keycloak',    keycloakState)

    let configUrl = util.middleware()
    configUrl += '/config/frontend'

    const configResponse = await axios.get(configUrl)
    Object.assign(globalState, {
        config: configResponse.data
    })

    const globalKeycloakConfig = globalState.config.keycloakconfig

    const keycloakConfig = {
        url:      globalKeycloakConfig.baseurl,
        realm:    globalKeycloakConfig.realm,
        clientId: globalKeycloakConfig.client,
        accessToken: (new URL(window.location)).searchParams.get('access_token'),
        refreshToken: (new URL(window.location)).searchParams.get('refresh_token')
    }

    if (keycloakConfig.accessToken) {
        globalState.openInApp = true
    }

    const keycloakInstance = await connectKeycloak(keycloakConfig)
    Object.assign(keycloakState, {
        available: true,
        instance:  keycloakInstance
    })

    let rideProviderUrl = util.middleware()
    rideProviderUrl += '/rideproviders'
    rideProviderUrl += '?externalReference=' + keycloakState.instance.tokenParsed.sub

    const rideProviderResponse = await axios.get(rideProviderUrl)
    const driver = rideProviderResponse.data?.at(0) || undefined

    Object.assign(keycloakState, {
        driverId:   driver?.ID_Fahrterbringer || null
    })

    return true
}

async function routingSetup() {
    router.beforeEach((to, from, next) => {
        if (to.meta.mustbedriver && (!keycloakState.driverId || keycloakState.driverId === null)) {
            next('/')
        } else {
            next()
        }
    })

    app.use(router)

    return true
}

async function componentSetup() {
    app.use(ToastPlugin, {
        // Toasts per Default oben anzeigen
        position: 'top'
    })
    app.use(appIcons)
    app.use(VueDatePicker)
    return true
}

setup()
.then(() => {
    return routingSetup()
})
.then(() => {
    return componentSetup()
})
.then(() => {
    app.mount('#app')
})