<template>
    <div class="date-interval-box">
        <DateIntervalInput  v-for="(interval, index) in inputValue" :inputid="'input-interval-'+interval.id"  :key="index"  :interval="interval" :intervalIndex="index" @date-from-input="handleFromInput" @date-until-input="handleUntilInput" @remove-interval="removeInterval"/>
        <div class="add-interval">
            <button class="primary round" name="addingInterval" value="+" @click="addInterval">
                <IconsAdd/>
            </button>          
            <label class="plusbuttonlabel" for="addingInterval" @click="addInterval" >Zeitbereich hinzufügen</label>
        </div>
    </div>
</template>
<script>
import DateIntervalInput from '@elements/DateIntervalInput.vue'
const EMIT_INPUT = 'update:modelValue'
const EMIT_DATEFROM_INPUT = 'date-from-input'
const EMIT_DATEUNTIL_INPUT = 'date-until-input'
const EMIT_ADD_INTERVAL = 'add-interval'
const EMIT_REMOVE_INTERVAL = 'remove-interval'

export default {
    name: 'DateIntervalBox',
    emits: [ EMIT_INPUT, EMIT_DATEFROM_INPUT, EMIT_DATEUNTIL_INPUT, EMIT_ADD_INTERVAL, EMIT_REMOVE_INTERVAL],
    props: {
        modelValue: {type: Array}
    },
    methods: {
        handleFromInput: function(value, index) {
            this.$emit(EMIT_DATEFROM_INPUT, value, index)
        },
        handleUntilInput: function(value, index) {
            this.$emit(EMIT_DATEUNTIL_INPUT, value, index)
        },
        addInterval: function() {
            this.$emit(EMIT_ADD_INTERVAL)
        },
        removeInterval: function(index) {
            this.$emit(EMIT_REMOVE_INTERVAL, index)
        }
    },
    components: {
        DateIntervalInput
    },
    computed: {
        inputValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit(EMIT_INPUT, value)
            }
        }
    }    
}
</script>

<style lang="scss" scoped>

.date-interval-box{
    margin-top: 10px;
    margin-bottom: 20px;

    .add-interval{
        display: flex;
        align-items: center;
    }

}

</style>