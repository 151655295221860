<template>
    <div class="custom-checkbox">
        <IconsCheckBoxChecked v-if="model" class="icon-checkbox" :for="computedid"/>
        <IconsCheckBoxOutline v-else class="icon-checkbox" :for="computedid"/>
        <div class="check-container">
            <input type="checkbox" :id="computedid" name="check-input" v-model="model" :value="modelValue">
            <label class="check-label" :for="computedid">{{ this.label }}</label>        
        </div>

    </div>
</template>

<script>
const EMIT_INPUT = 'update:modelValue'

export default {
    name: 'CheckBoxInput',
    emits: [ EMIT_INPUT ],

    props: {
        modelValue: {
            type: Boolean
        },
        label: String,
    },
    computed:{
      model:{
         get() {
            return this.modelValue;
          },
          set(value) {
            this.$emit(EMIT_INPUT, value);
          }
      },
      computedid: function() {
            return `check-input-${this.label}`            
        }      
    }

}
</script>

<style lang="scss" scoped>

.custom-checkbox{
    display: flex;

    .icon-checkbox{
            z-index: 0;
        }
    .check-container{
        display:flex;
        position: relative;
        left: -16px; 

        input[type="checkbox"] {
            width: 18px;
            // display: none;
            
            cursor: pointer;
            opacity: 0;
            z-index: 10;
        }


        .check-label {
            margin-left: 8px;
        }    
    }
}
    
</style>