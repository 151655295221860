<template>
    <div id="page">
        <div class="content">
            <div v-if="isloading" class="loading">
                <LoadingEllipsis />
            </div>
            <div v-else-if="keycloakInstance.authenticated">
                <div v-if="showError" class="errorMessage">
                    <IconsError/>
                    <span>{{ errorText }}</span>
                </div>
                <div v-else>
                    <div v-if="driverId === null" class="title">
                        <p> Hallo {{ keycloakInstance.tokenParsed.given_name}}!</p>
                        <p> Du bist noch nicht als Fahrer registriert. </p>
                        <div v-if="organisations.length > 1" class="organisations">
                            <label>Anmelden für</label>
                            <select v-model="selectedorga" name="organisationlist" id="organisationlist" class="selection">
                                <option v-for="orga in organisations"  :key="orga.iD_Organisation" :value="orga.iD_Organisation">{{orga.firmenname}}</option>
                            </select>
                        </div>
                        <div v-if="registrationrunning">
                                <LoadingEllipsis />
                        </div>
                        <!-- <div class="data-protection">
                            <input type="checkbox">
                            <label>Ich habe die Datenschutzbestimmungen gelesen und stimme zu</label>
                        </div> -->
                        <button @click="registerDriver" class="primary register">
                            <IconsRegister/>
                            <span>Registrieren</span>
                        </button>
                    </div>
                    <div v-else-if="!driverId">
                        <label> Fahrer-Informationen konnten nicht geladen werden. Bitte versuche es später noch einmal.</label>
                    </div>
                    <div v-else class="title">
                        <label> Hallo {{givenName}}, willkommen im Mitfahrerportal. Was möchtest du tun?</label>
                        <div class="option-container">
                            <div class="option">
                                <label class="option-title">Meine Fahrtangebote</label>
                                <p class="option-text">Verwalte hier deine bereits angelegten Fahrten</p>
                                <button @click="goToMyRideOffers" class="primary">
                                    <IconsCar/>
                                    <span>Verwalten</span>
                                </button>
                            </div>
                            <div class="option">
                                <label class="option-title">Neue Fahrt</label>
                                <p class="option-text">Erstelle eine neue Mitfahrt</p>
                                <button @click="goToNewRideOffer" class="primary">
                                    <IconsAdd/>
                                    <span>Erstellen</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
            <label> Hallo,
                <br>
                du bist nicht angemeldet.
                <br>
                Bitte melde dich an, um deine angebotenen Fahrten zu verwalten. </label>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingEllipsis from '@components/systems/elements/LoadingEllipsis.vue'
import axios from 'axios'

export default {
    name: 'home-view',
    inject: [ 'util', 'keycloak' ],
    data() {
        return {
            registrationrunning: false,
            organisations: [],
            selectedorga: 0,
            isloading: true,
            showError: false,
            errorText: ''
        }
    },
    computed: {
        keycloakInstance() {
            return this.keycloak.instance
        },
        driverId() {
            return this.keycloak.driverId
        },
        givenName(){
            return this.keycloak.instance.tokenParsed.given_name.trim()
        }
    },
    async mounted() {
        await this.loadOrganisations()
        this.isloading = false
    },
    methods: {
        async loadOrganisations() {
            let url = this.util.middleware() + '/organisations'

            return axios.get(url)
            .then(result => {
                this.organisations = result.data
                if (this.organisations.length == 0) {
                    this.showError = true
                    this.errorText = 'Es ist keine Organisation für die Fahrerregistrierung hinterlegt. Bitte wenden Sie sich an den Systemadministrator'
                }
            })
            .catch(err => {
                console.log(err)
                this.showError = true
                this.errorText = 'Laden der Organisationen fehlgeschlagen. Versuchen Sie es später erneut.'                
            })
        },
        registerDriver() {
            if (this.organisations.length == 1) {
                this.selectedorga = this.organisations[0].iD_Organisation
            }
            if (this.selectedorga == 0) {
                this.$toast.error('Bitte wählen Sie eine Organisation aus')
                return
            }

            this.registrationrunning = true

            let fahrterbringerRequest = {
                //TODO Klären was hier verwendet werden soll. evtl besser überall sub benutzen, damit wir keine personenbezogenen Daten dort haben
                ExterneReferenz: this.keycloakInstance.tokenParsed.sub,
                Bezeichner: this.keycloakInstance.tokenParsed.email,
                Firmenname: this.keycloakInstance.tokenParsed.given_name,
                Email: this.keycloakInstance.tokenParsed.email,
                ID_Organisation: this.selectedorga
            }

            let url = this.util.middleware() + '/rideproviders'

            axios.post(url, fahrterbringerRequest)
            .then(response => {
                this.keycloak.driverId = response.data.ID_Fahrterbringer
                this.$toast.success('Registrierung als Fahrer erfolgreich')
                this.registrationrunning = false
            })
            .catch(err => {
                this.registrationrunning = false
                this.$toast.error(err)
            })
        },
        goToMyRideOffers() {
            this.$router.push('/my-rideoffers')
        },
        goToNewRideOffer() {
            this.$router.push('/rideoffer')
        }
    },
    components: {
    LoadingEllipsis
}
}
</script>
<style lang="scss" scoped>

.errorMessage {
    display: flex;
    align-items: center;
    margin: 40px;
    font-size: 16px;
    font-weight: 600;

    > span {
        color: var(--sys-error);
        margin-left: 5px;
    }

    > svg {
        color: var(--sys-on-surface); 
    }
}



.loading {
    margin: 50px;
}


.title{
    margin: 40px;
    font-size:16px;

    :deep(p) {
        margin: 15px 0px;
    }


    .organisations{
        margin: 15px 0px;
        .selection{
            margin: 0px 15px;
            width: 150px;
            padding: 5px;
            border-radius: 4px;
            border: solid 1px var(--sys-outline);
            background-color: var(--sys-surface-bright);
            font-size: 16px;
        }
    }

}



.register {
    width: 142px;
}

.option-container{
    margin: 40px 0px;
    width: 100%;
    .option {
        display: inline-block;
        width: 330px;
        height: 165px;
        margin: 0px 30px 0px 0px;
        padding: 24px;
        border-radius: 18px;
        box-shadow: 0 3px 6px 0 var(--sys-shadow);
        background-color: var(--sys-surface);

        .option-title {
            display: block;
            height: 25px;
            font-size: 22px;
            font-weight: 500;
            margin: 0 0 4px;
            font-size: 22px;
            font-weight: 600;
            color: var(--sys-on-surface);
        }

        .option-text {
            display: block;
            height: 32px;
            margin: 4px 0px;
            font-size: 14px;
            color: var(--sys-on-surface);
        }
    }
}





@media (max-width: 750px) {

    .content {
        width: 100%;
    }
    .title{
        margin: 16px;
    }
    .option-container{
        margin: 20px 0px 16px 0px;
        padding-left: 0px;
        .option {
            margin-bottom: 20px;
            margin: 0px 20px 20px 0px;
        }
    }

}


</style>