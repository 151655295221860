const middleware = () => {
    return window?.configs?.['VUE_APP_MIDDLEWARE_URL'] || process.env['VUE_APP_MIDDLEWARE_URL']
}

const formatCurrency = (valueInCent) => {
    if (valueInCent === undefined) return undefined
    return (valueInCent/100).toFixed(2)
}

const formatDisplayDate = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    return [
        twoDigitNumber(date.getDate()),
        twoDigitNumber(date.getMonth() + 1),
        date.getFullYear(),
    ].join('.');
}

const formatDisplayTime = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    return [
        twoDigitNumber(date.getHours()), 
        twoDigitNumber(date.getMinutes())
    ].join(':');
}

const twoDigitNumber = (number) => {
    return ('0' + number).slice(-2)
}

export default {
    middleware,
    formatCurrency,
    formatDisplayDate,
    formatDisplayTime,
    twoDigitNumber
}