<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3 20c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 1 18V4c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 3 2h1V0h2v2h8V0h2v2h1c.55 0 1.02.196 1.413.587C18.803 2.98 19 3.45 19 4v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 17 20H3zm0-2h14V8H3v10zM3 6h14V4H3v2zm.038 0V4v2zM10 12a.968.968 0 0 1-.713-.287A.968.968 0 0 1 9 11c0-.283.096-.52.287-.713A.968.968 0 0 1 10 10c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 10 12zm-4 0a.968.968 0 0 1-.713-.287A.968.968 0 0 1 5 11c0-.283.096-.52.287-.713A.968.968 0 0 1 6 10c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 6 12zm8 0a.968.968 0 0 1-.713-.287A.968.968 0 0 1 13 11c0-.283.096-.52.287-.713A.968.968 0 0 1 14 10c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 14 12zm-4 4a.968.968 0 0 1-.713-.287A.968.968 0 0 1 9 15c0-.283.096-.52.287-.713A.968.968 0 0 1 10 14c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 10 16zm-4 0a.968.968 0 0 1-.713-.287A.968.968 0 0 1 5 15c0-.283.096-.52.287-.713A.968.968 0 0 1 6 14c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 6 16zm8 0a.968.968 0 0 1-.713-.287A.968.968 0 0 1 13 15c0-.283.096-.52.287-.713A.968.968 0 0 1 14 14c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 14 16z"
            fill="currentColor"
            fill-rule="nonzero"
        />
    </svg>
</template>
