<template>
    <div class="sandbar-container" @click="$event.stopPropagation()">
        <div class="account" @click="toggleAccountDetails">
            <label class="account-label">{{ initials }}</label>
        </div>
        <div v-show="accountdetailsopen" class="account-details">
            <AccountDetails/>
        </div>
    </div>
</template>

<script>
import AccountDetails from '@systems/AccountDetails.vue'

export default {
    name: 'account-sandbar',
    inject: [ 'util', 'keycloak' ],
    data() {
        return {
            accountdetailsopen: false
        }
    },
    computed: {
        keycloakInstance() {
            return this.keycloak.instance
        },
        initials() {
            return (this.keycloakInstance.tokenParsed.given_name?.charAt(0) || '') + (this.keycloakInstance.tokenParsed.family_name?.charAt(0) || '');
        }
    },
    methods: {
        toggleAccountDetails(event) {
            this.accountdetailsopen = !this.accountdetailsopen

            if (this.accountdetailsopen) {
                if (event) {
                    event.stopPropagation()
                }

                // register click-outside event on body
                document.addEventListener('click', this.closeAccountDetails)
            } else {
                document.removeEventListener('click', this.closeAccountDetails)
            }
        },
        closeAccountDetails() {
            this.accountdetailsopen = false
        },
        logout: function() {
            this.keycloakInstance.logout()
        }
    },
    components: {
        AccountDetails
    }
}
</script>

<style lang="scss" scoped>
.sandbar-container {
    position: absolute;
    top: 15px;
    right: 20px;
    /* padding: 0 10px;
    height: 50px;
    width:50px;
    z-index: 20;
    box-sizing: border-box; */

    >.account{
        width: 50px;
        height: 50px;
        padding: 15px 12px;
        box-shadow: 0 1px 4px 0 var(--sys-shadow);
        border: solid 1px var(--sys-on-primary-container);
        background-color: var(--sys-primary-container);
        border-radius: 25px;
        >.account-label{
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
        }
        >.account-label:hover{
            cursor: pointer;
        }

    }
    .account:hover {
        cursor: pointer;
    }

    .account-details {
        width: 236px;
        position: absolute;
        right: 0px;
        top: 56px;
        border-radius: 8px;
        box-shadow: 0 3px 6px 0 var(--sys-shadow);
        background-color: var(--sys-surface);
        z-index: 20;
        padding: 8px 0px 8px 0px;
    }
}


</style>