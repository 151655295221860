<template>
    <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
    <div v-else class="input-wrapper" :class="[{ 'error' : error }, { 'with-label' : label }]">
        <slot v-if="!display" ></slot>
        <label v-else>{{ modelValue }}</label>
        <label :for="inputid" class="input-label" v-if="label" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''">{{ this.label }} {{ required ? '*' : '' }}</label>
        <input :id="computedid" autocomplete="off" :min="min" @click="clicked" v-if="!display" :class="{ 'with-label' : this.label }" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''" :type="type" :value="modelValue" @input="handleInput($event.target.value)" :placeholder="placeholder" :disabled="disabled"/>
        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
const EMIT_VALUE = 'update:modelValue'
const EMIT_CLICK = 'click'

export default {
    name: 'TextInput',
    emits: [ EMIT_VALUE, EMIT_CLICK ],
    props: {
        modelValue: {
            type: String
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        display: Boolean,
        isloading: Boolean,
        disabled: Boolean,
        label: String,
        required: Boolean,
        error: Boolean,
        errortext: String,
        backgroundcolor: String,
        min: Number,
        inputid: String
    },
    methods: {
        handleInput: function(value) {
            this.$emit(EMIT_VALUE, value)
        },
        clicked: function(event) {
            this.$emit(EMIT_CLICK, event)
        }
    },
    computed: {
        computedid: function() {
            const externalId = this.inputid || ''
            return `input-${externalId}`            
        }
    }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
    position: relative;
    .with-label {
        padding-top: 20px;

        > .input-label {
            position: absolute;
            font-size: 1em;
            left: 10px;
            background: #fff;
            top: 10px;
            padding: 0px 5px;
            transition: .1s all;
            cursor: text;
        }
    }
}



/* https://zellwk.com/blog/check-empty-input-css/ */
// input:not(:placeholder-shown) + .input-label,
// input:focus + .input-label {
//     top: 0px;
//     left: 0px;
// }

input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    font-size: 1.4em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 8px 10px 8px;
    border-radius: 10px;
    border: 1px solid var(--framecolor, #e3e3e3)
}

input:hover  {
    border: 1px solid var(--framehovercolor, #064A6C);
}

input::placeholder {
    font-size: 1em;
}


@media (max-width: 800px) {
.input-wrapper{
	display: block;
	float: left;
    width: 100%; /* The width is 100%, when the viewport is 800px or smaller */
  }

input{
	display: block;
	float: left;
    width: 100%; /* The width is 100%, when the viewport is 800px or smaller */
  }
}

.error-container {
    margin-top: 3px;
    padding-left: 10px;
    color: red;
}

.error-container > .error-label {
    visibility: hidden;
    color: red;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error >>> * {
    color: red;
}

.input-wrapper.error > input {
    border: 1px solid red;
}

</style>