<template>
    <div class="account-info">
        <label class="title">Account</label>
        <div v-if="keycloakInstance.authenticated">
            <div class="name">
                <label>{{ keycloakInstance.tokenParsed.name }}</label>
            </div>
            <div class="email">
                <label>{{ keycloakInstance.tokenParsed.email }}</label>
            </div>
        </div>
    </div>
    <div v-if="driverId !== null" class="account-item"  @click="unregisterDriver">
        <label>Fahrer-Account löschen</label>
        <IconsDelete class="icon"/>
    </div>
    <div v-if="!openInApp" class="account-item"  @click="logout">
        <label>Abmelden</label>
        <IconsLogout class="icon"/>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'account-details',
    inject: [ 'util', 'keycloak', 'globalState' ],
    computed: {
        keycloakInstance() {
            return this.keycloak.instance
        },
        driverId() {
            return this.keycloak.driverId
        },
        openInApp() {
            return this.globalState.openInApp
        }
    },
    methods: {
        logout: function() {
            this.keycloakInstance.logout()
        },
        unregisterDriver: function() {
            // this.registrationrunning = true
            //prüfen, ob noch Fahrten hinterlegt sind.
            let cntrides = -1
            let url = this.util.middleware() + '/rideproviders'
            url += `/${this.driverId}` + '/rideoffers'
            axios.get(url)
            .then(result => {
                if(result.status === 200) {
                    cntrides = result.data.length
                    if (cntrides == 0) {
                        this.deleteDriveraccount()
                    }
                    if (cntrides > 0) {
                        //Gibt noch rides --> diese nach Nachfrage deaktivieren
// {
//                         title: "Fahrtangebote löschen?",
//                         message: "Mit deinem Account sind noch Fahrtangebote verknüpft. Wenn du den Fahrer-Account löschst, werden auch diese Fahrtangebote gelöscht.",
//                         okButtonText: "Löschen",
//                         cancelButtonText: "Abbrechen"
//                         }
                        if(confirm('Mit deinem Account sind noch Fahrtangebote verknüpft. Wenn du den Fahrer-Account löschst, werden auch diese Fahrtangebote gelöscht.')) {
                            url = this.util.middleware() + '/rideproviders'
                            url += `/${this.driverId}` + '/rideoffers'

                            axios.delete(url)
                            .then(result => {
                                if(result.status === 200) {
                                    //Löschen erfolgreich, dann auch Fahrterbringer löschen
                                    this.deleteDriveraccount()
                                } else {
                                    this.$toast.error('Fehler beim Löschen der Fahrten.')
                                }
                            })
                            .catch(err => {
                                this.$toast.error('Fehler beim Löschen der Fahrten: ' + err)
                            })
                        }

                    }
                } else {
                    this.$toast.error('Fehler beim Prüfen der vorhandenen Fahrten.')
                }
            })
            .catch(err => {
                this.$toast.error('Fehler beim Prüfen der vorhandenen Fahrten: ' + err)
            })
        },
        deleteDriveraccount: function () {
            //Fahrterbringer löschen
            let url = this.util.middleware() + '/rideproviders'
            url += `/${this.driverId}`
            axios.delete(url)
            .then(response => {
                if(response.status === 200) {
                    //null setzen. Undefined ist nur zu Beginn, bzw. falls Backend nicht erreichbar
                    this.keycloak.driverId = null
                    this.keycloak.driverName = null
                    this.$toast.success('Löschen des Fahrer-Accounts erfolgreich')
                } else {
                    // Fehler-Info anzeigen
                    this.$toast.error('Fehler beim Löschen des Accounts')
                }
                // this.registrationrunning = false
            })
            .catch(err => {
                // this.registrationrunning = false
                this.$toast.error(err)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.account-info {
    padding: 8px 16px 8px 16px;
    border-bottom: solid 1px var(--sys-outline);
    >.title{
        font-size: 14px;
        font-size: 14px;
        font-weight: 600;
        color: var(--sys-on-surface);
    }



    :deep(.name) {
        margin-top: 4px;
        font-size: 16px;
        color: var(--sys-on-surface);
    }
    :deep(.email) {
        margin-top: 2px;
        font-size: 12px;
        color: var(--sys-on-surface);
    }

}
.account-item{
    margin: 0px 16px;
    padding: 15px 0px;
    border-bottom: solid 1px var(--sys-outline-variant);
    :deep(label) {
        font-size: 14px;
        font-weight: 600;
    }
    :deep(.icon) {
        position: absolute;
        right: 15px;
    }
    :deep(label:hover) {
        cursor: pointer;
    }
}
.account-item:hover{
    cursor: pointer;
}


@media (max-width: 600px) {
    .account-info {
        padding: 8px 0px 8px 0px;
        margin: 0px 16px;
        border-bottom: solid 1px var(--sys-outline-variant);;
    }
}

</style>